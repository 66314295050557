import { Mutation, Action } from 'vuex-module-decorators';
import { ApiModule } from './ApiModule';
import { uniqueList } from '~/utils/helpers';

const ENTRIES_PER_PAGE = 60;
export abstract class PaginatedApiModule extends ApiModule {
  currentPage: number = 1;
  lastPage: boolean = false;
  types: string[] = [];
  protected recordKey: string = '';

  @Action
  async fetchFilteredRecords(filter: Record<string, string>) {
    try {
      this.context.commit('loadTable');
      const response = await this.$api.get(this.serviceUrl, {
        params: {
          page: this.page,
          filter: filter ? JSON.stringify({ ...filter }) : '{}',
        },
      });
      this.context.commit('setPaginatedRecords', response.data);
    } catch (e) {
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  setPaginatedRecords(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.lastPage = response.data.length < ENTRIES_PER_PAGE;
    this.records = response.data;
    this.types = response.options.types;
  }

  @Action
  async fetchNextPage(filter: Record<string, string>) {
    try {
      this.context.commit('loadTable');
      const response = await this.$api.get(this.serviceUrl, {
        params: {
          page: this.page,
          filter: filter ? JSON.stringify({ ...filter }) : '{}',
          limit: ENTRIES_PER_PAGE,
        },
      });
      this.context.commit('showNextPaginatedPage', response.data);
    } catch (e) {
      console.log(e);
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  showNextPaginatedPage(response: { data: any[]; options: Record<string, string[]> }): void {
    this.currentPage++;
    this.tableLoading = false;
    this.lastPage = response.data.length < ENTRIES_PER_PAGE;
    this.records = uniqueList([...this.records, ...response.data], this.recordKey);
    this.types = response.options.types;
  }

  @Mutation
  resetPagination(): void {
    this.currentPage = 1;
    this.lastPage = false;
  }

  get getIsLastPaginatedPage(): boolean {
    return this.lastPage;
  }

  get page(): number {
    return this.currentPage;
  }

  get recordTypes() {
    return this.types;
  }
}
